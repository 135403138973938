import ScrollTo from 'stimulus-scroll-to'

export default class extends ScrollTo {
  static targets = ['nav']

  #activeLink =
    'bg-gray-100 text-gray-900 group flex items-center px-4 py-3 text-sm font-normal rounded-md text-ellipsis'
  #notActiveLink =
    'text-gray-500 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-4 py-3 text-sm font-normal rounded-md'
  #headerItems = []

  onScroll() {
    this.#headerItems.forEach((item, index) => {
      let currentItemTopPosition = item.getBoundingClientRect().top
      let deleteClass = () => {
        let elements = document.getElementsByClassName(this.#activeLink)
        if (elements.length !== 0) {
          elements[0].className = this.#notActiveLink
        }
      }

      if (index === 0 && currentItemTopPosition > 300) {
        deleteClass()
      }

      if (currentItemTopPosition < 100 && currentItemTopPosition > -10) {
        let navItem = document.querySelectorAll(`[href="#${item.id}"]`)[0]
        deleteClass()
        navItem.className = this.#activeLink
      }
    })
  }

  connect() {
    super.connect()

    if (this.hasNavTarget) {
      const articleElement = document.querySelector('.article')
      for (const item of articleElement.getElementsByTagName('h2')) {
        if (item.classList.toString().includes('sr-only') || item.classList.toString().includes('exclude')) {
          continue
        }

        this.#headerItems.push(item)

        let tag = document.createElement('a')
        let text = document.createTextNode(item.innerHTML)
        tag.appendChild(text)
        tag.className += this.#notActiveLink
        tag.setAttribute('href', '#' + item.id)
        tag.setAttribute('data-controller', 'entrepreneur-school')
        tag.setAttribute('data-scroll-to-offset-value', '10')
        tag.setAttribute('data-scroll-to-behavior-value', 'smooth')
        this.navTarget.appendChild(tag)
      }
    }
  }

  scroll(event) {
    event.preventDefault()

    if (this.hasNavTarget) {
      return
    }

    const id = this.element.attributes.href.value.replace(/^#/, '')
    const target = document.getElementById(id)
    if (!target) {
      console.warn(`[stimulus-scroll-to] The element with the id: "${id}" does not exist on the page.`)
      return
    }
    const elementPosition = target.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = elementPosition - this.offset
    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior,
    })
  }
}
