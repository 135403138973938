import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'noDeadlinesCard',
    'card',
    'calendarCell',
    'cardItem',
    'asInput',
    'asLabel',
    'enkLabel',
    'taxReturn',
    'salary',
    'valueAddedTax',
    'expired',
  ]

  connect() {
    this.updateCards()
  }

  updateCards() {
    let as = this.asInputTarget.checked
    let taxReturn = this.taxReturnTarget.checked
    let salary = this.salaryTarget.checked
    let valueAddedTax = this.valueAddedTaxTarget.checked
    let expired = this.expiredTarget.checked

    let filterCondition = (el) => {
      console.log(Date.now())
      console.log(Date.parse(el.attributes['date'].value))
      console.log(Date.now() <= Date.parse(el.attributes['date'].value))
      console.log(Date.now() < Date.parse(el.attributes['date'].value))
      return (
        ((taxReturn && el.attributes['group'].value.includes('Tax return')) ||
          (salary && el.attributes['group'].value.includes('Salary')) ||
          (valueAddedTax && el.attributes['group'].value.includes('VAT'))) &&
        ((as && el.attributes['company-type'].value.includes('AS')) ||
          (!as && el.attributes['company-type'].value.includes('ENK'))) &&
        (expired || new Date().setUTCHours(0, 0, 0, 0) <= Date.parse(el.attributes['date'].value))
      )
    }

    this.cardItemTargets.forEach((type) => {
      type.hidden = true
    })

    this.cardItemTargets
      .filter((el) => filterCondition(el))
      .forEach((el) => {
        el.hidden = false
      })

    this.calendarCellTargets.forEach((el) => {
      if (el.attributes['color-class'].value !== '') {
        el.attributes['color-class'].value.split(' ').forEach((val) => el.classList.remove(val))
      }
    })

    this.calendarCellTargets
      .filter((el) => filterCondition(el))
      .forEach((el) => {
        if (el.attributes['color-class'].value !== '') {
          el.attributes['color-class'].value.split(' ').forEach((val) => el.classList.add(val))
        }
      })

    this.hideEmptyCards()
  }

  updateRadio() {
    let activeClassList = ['border-pink-luca', 'ring-2', 'ring-pink-luca']
    let notActiveClass = 'border-gray-300'
    if (this.asInputTarget.checked) {
      activeClassList.forEach((value) => {
        this.asLabelTarget.classList.add(value)
      })
      this.asLabelTarget.classList.remove(notActiveClass)
      activeClassList.forEach((value) => {
        this.enkLabelTarget.classList.remove(value)
      })
      this.enkLabelTarget.classList.add(notActiveClass)
    } else {
      activeClassList.forEach((value) => {
        this.enkLabelTarget.classList.add(value)
      })
      this.enkLabelTarget.classList.remove(notActiveClass)
      activeClassList.forEach((value) => {
        this.asLabelTarget.classList.remove(value)
      })
      this.asLabelTarget.classList.add(notActiveClass)
    }

    this.updateCards()
  }

  hideEmptyCards() {
    this.cardTargets.forEach((type) => {
      type.hidden = false
    })

    let cards = []
    this.calendarCellTargets.filter((el) => {
      let hidden = !el.attributes['class'].value.includes(el.attributes['color-class'].value)
      if (el.attributes['color-class'].value == '') {
        hidden = undefined
      }
      cards.push({ key: el.attributes['month'].value, hidden: hidden })
    })

    cards = this.groupBy(cards, (pet) => pet.key)
    let activeCardsCounter = 0
    for (const item of cards.keys()) {
      if (cards.get(item).filter((it) => it.hidden !== undefined && !it.hidden).length === 0) {
        activeCardsCounter += 1
        this.cardTargets
          .filter((card) => card.attributes['month'].value.includes(item))
          .forEach((card) => (card.hidden = true))
      }
    }

    this.noDeadlinesCardTarget.hidden = activeCardsCounter != cards.size
  }

  groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }
}
