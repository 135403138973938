// discount_calculator_controller.js
import { Controller } from '@hotwired/stimulus'
import FormattingHelper from '../classes/formatting_helper'

export default class extends Controller {
  static targets = [
    'calculationType',
    'input1',
    'input2',
    'input1Label',
    'input2Label',
    'output1',
    'output2',
    'output3',
    'output1Label',
    'output2Label',
    'output3Label',
    'output1Unit',
    'output2Unit',
    'output3Unit',
    'hideable',
  ]

  #map = {
    percent: {
      inputLabels: ['Rabatt (%)', 'Beløp'],
      outputLabels: ['Rabatt gitt', 'Prisforskjell', 'Rabattert pris'],
      outputUnit: ['Kr', 'Kr', 'Kr'],
      functions: [this.calculatePriceDifference, this.calculateAmountSaved, this.calculateAmountPaid],
    },
    before_after: {
      inputLabels: ['Førpris', 'Nypris'],
      outputLabels: ['Førpris', 'Nypris', 'Prosent endring'],
      outputUnit: ['Kr', 'Kr', '%'],
      functions: [this.originalValue, this.newValue, this.percentageChange],
    },
    absolute: {
      inputLabels: ['Førpris', 'Rabattbeløp'],
      outputLabels: ['Rabattbeløp', '', 'Ny pris'],
      outputUnit: ['Kr', 'Kr', 'Kr'],
      functions: [this.amountSaved, undefined, this.newPrice],
    },
    '2for1': {
      inputLabels: ['Beløp for første vare', 'Beløp for andre vare'],
      outputLabels: ['Gjennomsnittspris på varene', 'Kunden sparer', 'Totaltpris'],
      outputUnit: ['Kr', 'Kr', 'Kr'],
      functions: [this.averageCostOfOneItem, this.youSave, this.amountPaid],
    },
  }

  calculate() {
    let calculationType = this.calculationTypeTarget.value
    let input1 = parseFloat(this.input1Target.value)
    let input2 = parseFloat(this.input2Target.value)

    if (isNaN(input1) || isNaN(input2)) return

    let function1 = this.#map[calculationType]['functions'][0]
    let function2 = this.#map[calculationType]['functions'][1]
    let function3 = this.#map[calculationType]['functions'][2]

    if (function1 !== undefined)
      this.output1Target.textContent = FormattingHelper.norwegianNumber(function1(input1, input2))
    if (function2 !== undefined)
      this.output2Target.textContent = FormattingHelper.norwegianNumber(function2(input1, input2))
    if (function3 !== undefined)
      this.output3Target.textContent = FormattingHelper.norwegianNumber(function3(input1, input2))
  }

  updateLabels() {
    this.resetFieldValues()

    let calculationType = this.calculationTypeTarget.value

    const inputLabels = [this.input1LabelTarget, this.input2LabelTarget]
    inputLabels.forEach((element, i) => {
      element.textContent = this.#map[calculationType]['inputLabels'][i]
    })

    const outputLabels = [this.output1LabelTarget, this.output2LabelTarget, this.output3LabelTarget]
    outputLabels.forEach((element, i) => {
      element.textContent = this.#map[calculationType]['outputLabels'][i]
    })

    const outputUnits = [this.output1UnitTarget, this.output2UnitTarget, this.output3UnitTarget]
    outputUnits.forEach((element, i) => {
      element.textContent = this.#map[calculationType]['outputUnit'][i]
    })

    // hide third output field for 'Fixed amount off' calculating
    this.hideableTarget.hidden = !isNaN(this.#map[calculationType]['outputLabels'][1])
  }

  resetFieldValues() {
    this.input1Target.value = ''
    this.input2Target.value = ''
    this.output1Target.textContent = FormattingHelper.norwegianNumber(0)
    this.output2Target.textContent = FormattingHelper.norwegianNumber(0)
    this.output3Target.textContent = FormattingHelper.norwegianNumber(0)
  }

  // Discount off one item
  calculateAmountPaid(discountApplied, amount) {
    return amount - amount * (discountApplied / 100)
  }

  calculatePriceDifference(discountApplied, amount) {
    return amount * (discountApplied / 100)
  }

  calculateAmountSaved(discountApplied, amount) {
    return amount * (discountApplied / 100)
  }

  // Percentage increase/decrease
  percentageChange(oldValue, newValue) {
    return (newValue - oldValue) / (oldValue / 100)
  }

  originalValue(oldValue, _) {
    return oldValue
  }

  newValue(oldValue, newValue) {
    return newValue
  }

  // 'Fixed amount off'
  newPrice(originalPrice, discountAmount) {
    return originalPrice - discountAmount
  }

  amountSaved(originalPrice, discountAmount) {
    return discountAmount
  }

  // 2 for 1 discount
  amountPaid(amountForItem1, amountForItem2) {
    return amountForItem1 > amountForItem2 ? amountForItem1 : amountForItem2
  }

  averageCostOfOneItem(amountForItem1, amountForItem2) {
    return amountForItem1 > amountForItem2 ? amountForItem1 / 2 : amountForItem2 / 2
  }

  youSave(amountForItem1, amountForItem2) {
    return amountForItem1 > amountForItem2 ? amountForItem2 : amountForItem1
  }
}
