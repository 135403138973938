// profit_margin_calculator_controller.js
import { Controller } from '@hotwired/stimulus'
import FormattingHelper from '../classes/formatting_helper'

export default class extends Controller {
  static targets = ['salePrice', 'profit', 'grossMargin', 'itemCost', 'markup']

  #itemCost
  #markup

  calculate() {
    this.#itemCost = parseFloat(this.itemCostTarget.value)
    this.#markup = parseFloat(this.markupTarget.value)

    this.salePriceTarget.textContent = FormattingHelper.norwegianNumber(this.calculateSalePrice())
    this.profitTarget.textContent = FormattingHelper.norwegianNumber(this.calculateProfit())
    this.grossMarginTarget.textContent = FormattingHelper.norwegianNumber(this.calculateGrossMargin())
  }

  calculateSalePrice() {
    return this.#itemCost + this.calculateProfit()
  }

  calculateProfit() {
    return this.#itemCost * (this.#markup / 100)
  }

  calculateGrossMargin() {
    return this.calculateProfit() / (this.calculateSalePrice() / 100)
  }
}
