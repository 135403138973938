import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import ScrollTo from 'stimulus-scroll-to'

import '../js/search'
import 'alpinejs'
import AOS from 'aos'
import Cookies from 'js-cookie'

const application = Application.start()
application.register('scroll-to', ScrollTo)
const controllers = import.meta.glob('../**/*_controller.js', { eager: true })

registerControllers(application, controllers)

AOS.init({
  easing: 'ease-in-out-sine',
})

window.Cookies = Cookies
