// profit_margin_calculator_controller.js
import { Controller } from '@hotwired/stimulus'
import FormattingHelper from '../classes/formatting_helper'

export default class extends Controller {
  static targets = ['priceOfProduct', 'amountOfUnits', 'vatRate', 'priceBeforeVat', 'addedVat', 'total']

  calculate() {
    let vatRate = parseFloat(this.vatRateTarget.value)
    let priceOfProduct = parseFloat(this.priceOfProductTarget.value)
    let amountOfUnits = parseFloat(this.amountOfUnitsTarget.value)

    if (isNaN(priceOfProduct) || isNaN(amountOfUnits)) return

    let priceBeforeVat = this.calculatePriceBeforeVat(priceOfProduct, amountOfUnits)
    let addedVat = this.calculateAddedVat(priceBeforeVat, vatRate)
    let total = this.calculateTotal(priceBeforeVat, addedVat)

    this.priceBeforeVatTarget.textContent = FormattingHelper.norwegianNumber(priceBeforeVat)
    this.addedVatTarget.textContent = FormattingHelper.norwegianNumber(addedVat)
    this.totalTarget.textContent = FormattingHelper.norwegianNumber(total)
  }

  calculatePriceBeforeVat(priceOfProduct, amountOfUnits) {
    return priceOfProduct * amountOfUnits
  }

  calculateAddedVat(priceOfProduct, vatRate) {
    return (priceOfProduct / 100) * vatRate
  }

  calculateTotal(PriceBeforeVat, AddedVat) {
    return PriceBeforeVat + AddedVat
  }
}
